export const BASE_URL = `https://insureonline.site/api`;
export const CAR_INSURANCE_CATEGORY = `280fc9df-1dec-4631-8fe3-76a6f606d6ad`;
export const FILE_URL = `https://insureonline.site`;
export const DEFAULT_AVATAR = `/assets/43e1dab7-1c31-468c-b554-2ce85d93572c/728cd9da97ded3a06991bc742abba44f.png`;
export const CAR_DEFAULT_IMAGE = `https://www.pinclipart.com/picdir/big/118-1187597_nouvelle-porsche-911-icon-avto-podbor-bel-clipart.png`;
export const CORPORATE_EMAIL = `info@tuffstuff.co.za`;
export const CORPORATE_SUPPORT_EMAIL = `tuffstuff.info@yahoo.com`;
export const CORPORATE_PHONE = `+27 11 394 8235`;
export const CORPORATE_CELLPHONE = `0861 44 44 00`;
export const CORPORATE_ADDRESS = `397 Roslyn Avenue, Waterkloof Glen, Pretoria, 0010`;
export const CORPORATE_FAX = `+27 11 394 8917`;
export const COMPANY_NAME = `TUFFSTUFF`;
export const COMPANY_FOOTER_CONTENT = `TUFFSTUFF is underwritten by Western National Insurance Company Limited. FSP no. 9465. TUFFSTUFF is administered and marketed by REAM Insurance Brokers (Pty) Ltd. FSP no. 1422. This website is managed by REAM Insurance Brokers (Pty) Ltd. TUFFSTUFF is a specialised insurance product and registered trademark.`;
export const LINK_GET_QUOTE = `https://www.tuffstuff.co.za/insurance/quote`;
export const LINK_RENT_SATELITE_PHONE = `https://www.tuffstuff.co.za/insurance/satelitephone-enquiry`;
export const LINK_CARBON_FOOTPRINT = `https://www.tuffstuff.co.za/insurance/co2-calculator`;
export const LINK_PRIVACY_POLICY = `https://www.tuffstuff.co.za/insurance/website-privacy-policy`;
export const LINK_GALLERIES = `https://www.tuffstuff.co.za/insurance/galleries`;
export const LINK_EVENTS = `https://www.tuffstuff.co.za/insurance/events`;
export const LINK_BLOG = `https://www.tuffstuff.co.za/blog/insurance`;
export const LINK_GOOGLE_MAPS = `https://goo.gl/maps/tpWHxrSyxqbZ1JHM8`;

export const SUPPORT_EMAIL_DATA = {
    supportEmailSubject: `Tuffstuff online support`,
    supportEmailBody: `Hi Tuffstuff team,`
};
<template>
  <!-- Services-->
  <section class="page-section" id="services">
    <div class="container px-4 px-lg-5">
      <h2 class="text-center mt-0">Our Service</h2>
      <hr class="divider" />
      <div class="row gx-4 gx-lg-5">
        <div class="col-lg-3 col-md-6 text-center">
          <div class="mt-5">
            <div class="mb-2">
              <img
                src="../../assets/img/icon-ev.png"
                alt="icon"
                width="110"
                height="100"
              />
            </div>
            <h3 class="h4 mb-2">TUFFSTUFF 4x4 Insurance Product</h3>
            <p class="text-muted mb-0">
              Market leaders in all aspects of outdoor, lifestyle, 4x4 and all
              insurance products.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 text-center">
          <div class="mt-5">
            <div class="mb-2">
              <img
                src="../../assets/img/icon-helicopter.png"
                alt="icon"
                width="110"
                height="100"
              />
            </div>
            <h3 class="h4 mb-2">Airborne Medical Evacuation</h3>
            <p class="text-muted mb-0">
              Full airborne medical evacuation by qualified personnel while
              outside RSA and back to RSA.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 text-center">
          <div class="mt-5">
            <div class="mb-2">
              <img
                src="../../assets/img/icon-help.png"
                alt="icon"
                width="110"
                height="100"
              />
            </div>
            <h3 class="h4 mb-2">Emergency Accommodation</h3>
            <p class="text-muted mb-0">
              Whilst outside the RSA limited to R20 000 for any one incident and
              R1 000 pp per night.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 text-center">
          <div class="mt-5">
            <div class="mb-2">
              <img
                src="../../assets/img/icon-scale.png"
                alt="icon"
                width="110"
                height="100"
              />
            </div>
            <h3 class="h4 mb-2">International Legal Assistance</h3>
            <p class="text-muted mb-0">
              TUFFSTUFF allows you to receive international legal assistance
              cover up to R75 000.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>
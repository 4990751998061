<template>
  <section class="container">
    <div class="wrapper">
      <div class="products__box"></div>
      <div class="header__section">
        <h1 class="pageTitle">About</h1>
        <div class="pageSubTitle">Established in 1988</div>
      </div>
      <p>
        <span style="text-align: justify"
          >REAM Insurance Brokers provides specialist insurance intermediary
          services in South Africa. We’re passionate about providing insurance
          solutions that are tailored to our clients’ unique needs and
          budget.</span
        >
      </p>
      <h3 style="text-align: justify">
        <span style="font-weight: bold"
          >We provide sound advice and insurance products for:</span
        >
      </h3>
      <ul>
        <li style="text-align: justify">Commercial and industrial entities,</li>
        <li style="text-align: justify">Contractors,</li>
        <li style="text-align: justify">
          Earth moving equipment and machinery,
        </li>
        <li style="text-align: justify">Domestic buildings and contents,</li>
        <li style="text-align: justify">Private motor vehicles, and</li>
        <li style="text-align: justify">4x4 and outdoor lifestyle vehicles.</li>
      </ul>
      <p>
        <span style="text-align: justify"
          >Since our establishment in 1988, REAM has always focused on niche
          product development, rather than a "shotgun” approach to the
          acquisition of business. In this manner, REAM has always been able to
          successfully maintain a high level of service to our clients whilst
          ensuring the continued advancement of our product offering to existing
          clients as well as prospective new clients.</span
        >
      </p>
      <p style="text-align: justify">
        Furthermore, our understanding of the importance of properly educated
        and trained professional staff has resulted in a staff complement which
        is completely compliant with all the stringent requirements set down by
        the Financial Services Board.
      </p>
      <p style="text-align: justify">
        This approach to our business model has been the foundation of our
        success since 1988, and we are certain that it will continue to underpin
        our advancement in product development in the future and our value
        proposition to our clients.
      </p>
      <p style="text-align: justify">
        REAM Insurance Brokers is a registered Financial Services Provider (FSP
        licence no. 1422).
      </p>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    this.scrollToTop();
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 10em;
}
</style>
<template>
  <!-- About-->
  <section class="page-section bg-primary" id="about">
    <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5 justify-content-center">
        <div class="col-lg-8 text-center">
          <h2 class="text-white mt-0">LOCAL KNOWLEDGE & HELPFUL ADVICE</h2>
          <hr class="divider divider-light" />
          <p class="text-white-75 mb-4">
            We’ll take care of all your car insurance needs.
          </p>
          <router-link
            class="btn btn-light pt-3 pb-3 ps-5 pe-5"
            to="/registration"
            >Get insurance online</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>